import React from "react"
import { Layout } from "../components/Layout"

const equipo = props => {
  return (
    <Layout Jumbo={false}>
      <div className="legalContainer">
        <h2>Protección de datos</h2>
        <br />
        <h3>Responsable</h3>
        <p>
          SEMTE INSTALACIONES S.L, NIF B-66875774, C/ Josep Romeu i Torrello, nº
          3 local 2, 08830 Sant Boi de Llobregat, mail administracion@semte.es,
          tlf 930170466.
        </p>
        <h3>Finalidad</h3>
        <p>
          Gestión del Servicio demandado, realización de presupuestos,
          facturación, cobro, contabilidad, obligaciones fiscales. No se tomarán
          decisiones automatizadas dependiendo del perfil. Los datos
          proporcionados se mantendrán mientras exista una relación comercial
          siempre y cuando no se solicite expresamente por parte del interesado
          por el periodo necesario para el cumplimiento de las obligaciones
          legales.
        </p>
        <h3>Legitimidad</h3>
        <p>
          La base legal para el tratamiento de sus datos es la ejecución del
          contrato de los servicios demandados.
        </p>
        <h3>Destinatarios</h3>
        <p>
          Los datos se comunican a la Gestoría para impuestos, contabilidad,
          obligaciones fiscales, entidades bancarias para el cobro,
          administraciones públicas para la puesta en marcha de las
          instalaciones, fabricantes para las garantías de los aparatos
          instalados y a quien corresponda por obligación legal.
        </p>
        <h3>Derechos</h3>
        <p>
          A solicitar el acceso a sus datos, su modificación o la supresión de
          los mismos, a la limitación de su uso y a la portabilidad de los
          datos. Si quiere ejercer estos derechos puede solicitarlos en esta
          dirección: SEMTE INSTALACIONES S.L, NIF B-66875774, C/ Josep Romeu i
          Torrello, nº 3 local 2, 08830 Sant Boi de Llobregat, mail
          info@semte.es, tlf 930170466. También se puede presentar una
          reclamación ante la autoridad de control de Protección competente.
        </p>
      </div>
    </Layout>
  )
}

export default equipo
